function EndItem(props) {

    function showSimulationColumn(amount) {
      return(
          <div className="simulatebottom-round" style={{height: 64 + amount*2}}>
            <div className="simulatetop-round">
                {amount}
            </div>
          </div>
      )
    }

    return (
      <>
        <img src={require("../img/arrow1-end.png")} className='arrow1'/>
        <div className='item_startend'>
          {props.simulate ? showSimulationColumn(props.amount) : null}
          <div className='item_startend_front colorend_front'><img src={require("../img/icon_end.png")} /></div>
          <div className='item_startend_bottom colorend_bottom'></div>
          <div className="machinename" style={{left: -40}}>{props.name}</div>
        </div>
        </>
    );
  }

  
  export default EndItem;