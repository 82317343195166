import Confetti from 'react-confetti'
import {useEffect, useState} from 'react';

function ResultsInfo(props) {

    const [tab, setTab] = useState("overview");
    const [confettipieces, setConfettipieces] = useState(200);

    //const { width, height } = useWindowSize()
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }

      const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function getTotalCost() {
        return Math.round((props.info.cost + props.info.time * props.costsecond + props.info.scrap * props.costscrap)*100)/100;
    }
    
    function getCostPerUnit() {
        return Math.round(getTotalCost()/props.info.output * 100)/100;
    }

    function getMinute(time) {
        var minutes = Math.floor(time / 60);
        var seconds = time - minutes * 60;
        return (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
      }

      function getSuccess_old() {
          return props.info.output >= props.info.expectedoutput ? true : false;
      }

      function getSuccess() {
        return props?.maxprice >= getCostPerUnit() ? true : false;
    }

      function getMaxValueItem(val) {
          let color = "#71CE62";

          if(val < 10) {
            color = "#71CE62"
          } else if(val < 30) {
            color = "#EAB93D"
          } else {
            color = "#E14545"
          }

        return <span className='maxvalueitem' style={{backgroundColor: color}}>{val}</span>
      }

      function getFailureTime(time) {
        return time > 0 && (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 20, fontSize: 12}}>
          <img src={require("../img/warning_red.png")} style={{width: 20, marginRight: 6}}/>
          <p style={{margin: "0px auto"}}>Total downtime: <b>{time}s</b><br/>
          (<b>{Math.round(time/props.info.time * 100)}%</b> of total time)</p>
        </div>
        )
      }

      function getMachineData() {
        let counter = -1;
        console.log(props.simulationmachineinfo);
        return props.mymachines.map((step,stepindex) => {
          return step.map((obj, index) => {
            counter++;
            return (
              <>
                <div><span>{props.machines[obj[0]].machines[obj[1]]?.name}</span> <span style={{display: 'flex', flexDirection: 'row'}}> {getFailureTime(props.simulationmachineinfo[counter][1])} {getMaxValueItem(props.simulationmachineinfo[counter][0])}</span></div>
                <hr></hr>
              </>
            )
          })
        })

      }

      useEffect(() => {
        if(getSuccess()) {
          setTimeout(() => {
            setConfettipieces(0);
          }, 3000);
        }
      }, [])


    return (
      <div className="modalwrapper">
          <Confetti
            width={windowDimensions.width}
            height={windowDimensions.height}
            run={getSuccess()}
            numberOfPieces={confettipieces}
            />
          <div className="modalbg" onClick={() => props.onDismiss()}></div>
          <div className="modal modalresults" style={{display: 'flex'}}>
            <div className='modalcancel' onClick={() => props.onDismiss()}>
              <img src={require("../img/cancel-black.png")} />
            </div>
            <div className="modalcontent" style={{marginTop:0}}>
                <p style={{marginBottom: 4}}>Your price per unit</p>
                <span style={{color: getSuccess() ? '#57A74A' : '#E14545', fontSize: 56}}>{getCostPerUnit()}<span style={{fontSize: 32}}>$</span></span>
                <p style={{color: getSuccess() ? '#57A74A' : '#E14545', marginTop: 4}}>Maximum price: {props?.maxprice || 100}$. {getSuccess() ? 'You passed!' : 'Too expensive!'}</p>

                <div className='tabbuttongroup'>
                  <div className={`tabbutton ${tab == 'overview' ? "active" : ""}`} onClick={() => setTab("overview")}>Overview</div>
                  <div className={`tabbutton ${tab == 'machines' ? "active" : ""}`} onClick={() => setTab("machines")}>Machines</div>
                </div>
                <hr style={{marginBottom: 16}}></hr>

                <div className="resultsinfo-table">
                  {tab == "overview" ?
                    <>
                      <div><span>Time</span><span className="bigtext">{getMinute(props.info.time)}</span></div>
                      <hr></hr>
                      <div><span>Good products</span><span className="bigtext">{props.info.output}<span style={{fontSize: '70%'}}>/{props.info.input}</span></span></div>
                      <hr></hr>
                      <div><span>Machines & Workers</span>$ {parseFloat((props.info.cost).toFixed(2))}</div>
                      <div><span><b>{props.info.time}</b> seconds x {props.costsecond}$</span>$ {parseFloat((props.info.time * props.costsecond).toFixed(2))}</div>
                      <div><span><b>{props.info.scrap}</b> bad products {props.qualitygate ? "delivered (quality gate)" : `delivered x ${props.costscrap}$`}</span>$ {parseFloat((props.info.scrap * props.costscrap).toFixed(2))}</div>
                      <hr></hr>
                      <div><span>Total</span>$ {getTotalCost()}</div>
                      {/*<hr></hr>
                      <div><span>Price per unit</span><span className="bigtext greentext">$ {getCostPerUnit()}</span></div>*/}
                      {/*props.hideleaderboard == true ? <div className='bestresult'>Best result: <b style={{marginLeft: 4}}>{props.bestresult}</b></div> : null*/}
                    </>
                    :
                    getMachineData()
                  }
                </div>

                {getSuccess() ?
                  <div onClick={() => props.openLearnings()} className='blackbutton' style={{fontSize: 20}}>Finish round</div>
                  :
                  <div onClick={() => props.tryagain()} className='blackbutton'>↩️ Try again</div>
                }
            </div>
          </div>
      </div>
    );
  }
  
  export default ResultsInfo;