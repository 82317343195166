import { useState, useEffect} from 'react';

function PlaygroundChooseScenarioModal(props) {

    const [difficulty, setDifficulty] = useState(0);

    const difficultydata = [
        {
            title: "Easy",
            description: "No failures"
        },
        {
            title: "Medium",
            description: "Some failures"
        },
        {
            title: "Hard",
            description: "A lot of failures"
        },
    ]

  const presets = [
    {
        icon: "💍", 
        title: "Goldsmith", 
        description: "The Jeweler “Goldsmith Inc.” crafts engraved goldrings for weddings, and wants to optimise its production process. The process consists of four steps: Heat the metal, form the ring, engrave the message, and finally cool off the metal. Every single step has to be followed in this order. The goal is to minimise the cost per unit. The customers have high standards in terms of quality, so it is important to export as few bad products as possible.", 
        machines: [{
            "color": "#eb3b5a",
            "machines": [
                {
                    "icon": "🔥",
                    "name": "Mini-Gasburner",
                    "duration": 8,
                    "scrap": 40,
                    "price": 80
                },
                {
                    "icon": "🔆",
                    "name": "Heating Stove",
                    "duration": 3,
                    "scrap": 10,
                    "price": 150
                },
                {
                    "icon": "💥",
                    "name": "Powerheat 2000",
                    "duration": 1,
                    "scrap": 30,
                    "price": 320,
                }
            ]
        },
        {
            "color": "#f7b731",
            "machines": [
                {
                    "icon": "🔨",
                    "name": "Hammer",
                    "duration": 5,
                    "scrap": 60,
                    "price": 60
                },
                {
                    "icon": "💢",
                    "name": "Punching Machine",
                    "duration": 2,
                    "scrap": 20,
                    "price": 210,
                }
            ]
        },
        {
            "color": "#20bf6b",
            "machines": [
                {
                    "icon": "✏️",
                    "name": "Engraving Pen",
                    "duration": 6,
                    "scrap": "100",
                    "price": 70
                },
                {
                    "icon": "⚡️",
                    "name": "Precision Laser",
                    "duration": 2,
                    "scrap": 50,
                    "price": 290,
                }
            ]
        },
        {
            "color": "#2d98da",
            "machines": [
                {
                    "icon": "💨",
                    "name": "Ventilator",
                    "duration": 6,
                    "scrap": 18,
                    "price": 110,
                },
                {
                    "icon": "💧",
                    "name": "Cooling Bath",
                    "duration": 2,
                    "scrap": 14,
                    "price": 180
                },
                {
                    "icon": "❄️",
                    "name": "Freezemaster Deluxe",
                    "duration": 1,
                    "scrap": 60,
                    "price": 420,
                }
            ]
        }]
    },
    {
        icon: "🍩", 
        title: "Donut Bakery", 
        description: "The Bakery “Diana's Donuts” is new in town and is amazingly popular. Diana does not want to see her customers waiting too long in line, so she wants to find a way on how to bake donuts as quickly as possible. Every order consists of three steps: bake the donut dough, glaze it, and put on a topping. Of course only the best donuts should be served, so make sure to produce as few bad donuts as possible.", 
        machines: [{
            "color": "#f7b731",
            "machines": [
                {
                    "icon": "🔆",
                    "name": "Mini-Oven",
                    "duration": 3,
                    "scrap": 10,
                    "price": 150
                },
                {
                    "icon": "🔥",
                    "name": "Stone Oven",
                    "duration": 8,
                    "scrap": 40,
                    "price": 80
                },
                {
                    "icon": "🫕",
                    "name": "Fryer XXL",
                    "duration": 1,
                    "scrap": 30,
                    "price": 320,
                }
            ]
        },
        {
            "color": "#eb3b5a",
            "machines": [
                {
                    "icon": "🖌️",
                    "name": "Brush",
                    "duration": 5,
                    "scrap": 60,
                    "price": 60
                },
                {
                    "icon": "🍫",
                    "name": "Glazing Fountain",
                    "duration": 2,
                    "scrap": 20,
                    "price": 210,
                }
            ]
        },
        {
            "color": "#f368e0",
            "machines": [
                {
                    "icon": "🧂",
                    "name": "Sprinkler",
                    "duration": 6,
                    "scrap": "100",
                    "price": 70
                },
                {
                    "icon": "🍩",
                    "name": "Topping Rain",
                    "duration": 2,
                    "scrap": 50,
                    "price": 290,
                }
            ]
        },
        ]
    }
]

    return (
      <div className="modalwrapper">
          <div className="modalbg"></div>
          <div className="modal">
            <div className="modalcontent" style={{marginTop: 0}}>
                <h2>Choose a scenario</h2>
                <div style={{display: 'grid', gridTemplateColumns: "1fr 1fr", columnGap: 12}}>
                  {presets.map((obj, index) => 
                    <div key={index} onClick={() => props.setScenario(obj, difficulty)} className="choosescenariobutton"><p style={{fontSize: 48, margin: 0, marginBottom: 8}}>{obj.icon}</p><p style={{margin: 0, fontSize: 16}}>{obj.title}</p></div>
                  )}
                </div>

                <h3 style={{marginTop: 40}}>Difficulty</h3>
                <div style={{display: 'grid', gridTemplateColumns: "1fr 1fr 1fr", columnGap: 12}}>
                  {difficultydata.map((obj, index) => 
                    <div key={index} onClick={() => setDifficulty(index)} className={`choosedifficultybutton ${"choosedifficultybutton-" + obj.title} ${index == difficulty ? "choosedifficultybuttonactive" : ""}`}><p style={{margin: 0, fontSize: 16}}>{obj.title}</p><p style={{margin: 0, fontSize: 12, opacity: 0.6, marginTop: 2}}>{obj.description}</p></div>
                  )}
                </div>
            </div>
          </div>
      </div>
    );
  }
  
  export default PlaygroundChooseScenarioModal;