import { useState, useEffect } from 'react';


function BulkResults(props) {

    const [variations, setVariations] = useState([]);

    function getAllConfigurations(setup) {
        
      }

    return (
        <div className="modalwrapper">
            <div className="modalbg" onClick={() => props.onDismiss()}></div>
            <div className="modal" style={{maxHeight: '90vh', overflowY: 'scroll'}}>
                <div onClick={() => getAllConfigurations(props.setup)}>Get all variations</div>
            </div>
        </div>
    );
}

export default BulkResults;