import { useState, useEffect} from 'react';

function FinishGameModal(props) {

  const [text, setText] = useState('');

    return (
      <div className="modalwrapper">
          <div className="modalbg" onClick={() => props.onDismiss()}></div>
          <div className="modal" style={{maxHeight: '90vh', overflowY: 'scroll'}}>
            <div className="modalcontent" style={{marginTop: 0}}>
                <h2>Explain your approach</h2>
                <textarea value={text} onChange={e => setText(e.target.value)} className='intentiontextarea' placeholder='Why did you choose this setup, these machines, etc.'/>
                {/*<div onClick={() => text == "" ? alert("Explain your approach") : props.onfinish(text)} className={`${text == "" ? "finishsimulatebuttoninactive" : "finishsimulatebutton"}`}>Simulate now</div>*/}
                <div onClick={() => props.onfinish(text)} className="finishsimulatebutton">Simulate now</div>
            </div>
          </div>
      </div>
    );
  }
  
  export default FinishGameModal;