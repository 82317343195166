import './menupages.css';

function Loading() {

    return (
        <div className="loadingscreen">
            <div className='gradientbg'></div>
            <div className='loadingcontainer' style={{height: '100vh'}}><img src={require('./img/loading.png')} /></div>
        </div>
    );
  }
  
  export default Loading;