function MachineVisual(props) {

  function getClass() {
    if(props.building) {
      return "machinevisualbuilding";
    }
  }

    return (
      <div className={"machinevisual " + getClass()}>
        <div className="machinetop"><span style={{color: props.bgcolor}}>{props.icon}</span></div>
        <div className="machinebottom" style={{backgroundColor: props.bgcolor}}></div>
      </div>
    );
  }

  export default MachineVisual;