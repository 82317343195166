import { useState, useEffect} from 'react';

function GameInfoModal(props) {

    return (
      <div className="modalwrapper">
          <div className="modalbg" onClick={() => props.onDismiss()}></div>
          <div className="modal" style={{maxHeight: '90vh', overflowY: 'scroll'}}>
            <div className="modalcontent" style={{marginTop: 0}}>
                <h2>{props.title}</h2>
                {/*props?.videolink != undefined && <iframe className='videoframe' height="315" src={props?.videolink} title="Info video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>*/}
                {props?.videolink != undefined && <video width="100%" height="auto" controls><source src={"https://opex-api.simonrahm.pro/v2/img/" + props?.videolink} type="video/mp4" /></video>}
                {props?.caseimage != undefined && <img src={"https://opex-api.simonrahm.pro/v2/img/" + props?.caseimage[0]} style={{maxWidth: props?.caseimage[1]}} key={props?.caseimage[0]} />}
                <p className='gamedescriptiontext'>{props.description != "" ? props.description : "This game has no description."}</p>
                {props.tip && <div className='casetipbox'><img src={require("../img/lightbulb.png")} />{props.tip}</div>}
                <div onClick={() => props.onDismiss()} className='blackbutton'>Ok, got it!</div>
            </div>
          </div>
      </div>
    );
  }
  
  export default GameInfoModal;