import './menupages.css';
import { useState, useEffect, useMemo } from 'react';
import Loading from './Loading.js';
import Medal from './components/Medal.js';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import PlaygroundPreview from './components/PlaygroundPreview';


function Leaderboard() {

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({});

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [showplayground, setShowPlayground] = useState(false);

    const [chapterindex, setChapterindex] = useState(0);


    function getMinute(time) {
        var minutes = Math.floor(time / 60);
        var seconds = time - minutes * 60;
        return (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }

    const newdata = [
        {
            chapter: "The Bottleneck",
            ranking: [
                { name: "Markus Bayer", output: 74, time: 132, unitprice: 21.83 },
                { name: "Simon Rahm", mine: true, output: 69, time: 112, unitprice: 22.03 },
                { name: "Sandra Lehner", output: 76, time: 172, unitprice: 22.42 }
            ],
            myrounds: [
                { output: 74, time: 132, unitprice: 22, rationale: "", timestamp: "2023-10-16 09:12:59" },
                { output: 76, time: 146, unitprice: 24.2, rationale: "", timestamp: "2023-10-16 11:34:02" },
                { output: 74, time: 132, unitprice: 17.9, rationale: "", timestamp: "2023-10-16 09:12:59" },
                { output: 76, time: 146, unitprice: 22.1, rationale: "", timestamp: "2023-10-16 11:34:02" }
            ]
        },
        {
            chapter: "Skilled Workers",
            ranking: [
                { name: "Simon Rahm", mine: true, output: 69, time: 112, unitprice: 22.03 },
                { name: "Petra Huber", output: 76, time: 172, unitprice: 22.42 }
            ],
            myrounds: [
                { output: 74, time: 132, unitprice: 21.83, rationale: "", timestamp: "2023-10-16 09:12:59" },
            ]
        }
    ]

    // Function to calculate maxUnitPrice
    const calculateMaxUnitPrice = (data) => {
        let maxPrice = 0;

        if (data && data[chapterindex] && data[chapterindex].myrounds) {
            for (const item of data[chapterindex].myrounds) {
                if (item && item.unitprice > maxPrice) {
                    maxPrice = item.unitprice;
                }
            }
        }

        return maxPrice;
    };

    const minUnitPrice = 0; // Minimum unitprice
    const maxUnitPrice = useMemo(() => calculateMaxUnitPrice(data), [loading, data, chapterindex]);
    const heightRange = 160; // Total height range for the bars




    useEffect(() => {
        setLoading(true);
        fetch('https://opex-api.simonrahm.pro/v2/newleaderboard.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("uuid"),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                console.log(data);
                if (data == "fail") {
                    alert("We could not find that round. 🤷")
                    navigate('/');
                } else {
                    setData(data);
                    setLoading(false);
                    const findindex = data.findIndex(item => item.id === searchParams.get('id'));
                    if (findindex !== -1) {
                        setChapterindex(findindex);
                    }
                }
            })

    }, []);



    if (loading) {
        return (
            <Loading />
        )
    } else {
        return (
            <div className="gameoverview">
                <div className='gradientbg'></div>

                <div onClick={() => navigate("/gameoverview")}><img className='backicon' src={require('./img/icon_back.png')} /></div>
                <div className="centerwrapper">
                    <h1 style={{ textAlign: 'center' }}>🏆 Leaderboard</h1>

                    {data.length === 0 ? (
                        <div class="emptystate-text">No rounds played yet</div>
                    ) : (<>
                        <div className='chapterselectioncontainer'>
                            {
                                data.map((obj, index) => {
                                    return (
                                        <div onClick={() => setChapterindex(index)} className={`chapterselectionitem ${index == chapterindex && "chapterselectionitem-active"}`} key={index}>{obj.chapter}</div>
                                    )
                                })
                            }
                        </div>

                        {data[chapterindex]?.ranking.length > 0 && (
                            <>
                                {
                                    data[chapterindex].ranking.map((obj, index) => {
                                        return (
                                            <div className={`leaderboardcard ${obj?.mine == true && "leaderboardcard-highlighted"}`} key={index}>
                                                <div className='leaderboardcard-left'>
                                                    <Medal rank={index + 1} />
                                                    <div className='leaderboardcard-text'>
                                                        <p className='leaderboardcard-text-name'>{obj.name}</p>
                                                        <p className='leaderboardcard-text-subline'>Output: {obj.output}, Time: {getMinute(obj.time)}</p>
                                                    </div>
                                                </div>
                                                <span className={`leaderboardcard-unitprice ${parseFloat(data[chapterindex]?.maxprice) < parseFloat(obj.unitprice) && 'leaderboardcard-unitprice-red'}`}>$ {obj.unitprice}</span>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        )}


                        <p style={{ marginTop: 56 }}>My attempts ({data[chapterindex]?.myrounds.length})</p>
                        <div className={`timeline ${data[chapterindex].myrounds.length > 10 && "timeline-s"} ${data[chapterindex].myrounds.length > 20 && "timeline-xs"}`} style={{ marginTop: 24 }}>
                            {data[chapterindex].myrounds.length > 0 ? (
                                <>
                                    {
                                        data[chapterindex].myrounds.map((obj, index) => {
                                            const unitPrice = Math.min(maxUnitPrice, Math.max(minUnitPrice, obj.unitprice));
                                            const height = ((unitPrice - minUnitPrice) / (maxUnitPrice - minUnitPrice)) * heightRange;
                                            return (
                                                <div className='timelinecontainer' key={index}>
                                                    <div className='timelinebubble'>
                                                        <p>Unitprice: {obj.unitprice}</p>
                                                        <p>Time: {getMinute(obj.seconds)}</p>
                                                        <p>Output: {obj.output}</p>
                                                    </div>
                                                    <div className='timelineitem' style={{ height: height }}></div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <div class="emptystate-text">No data about this chapter yet</div>
                            )}
                        </div>

                        <div onClick={() => navigate("/gameoverview")} className='blackbutton' style={{marginTop: 40}}>Back to Overview</div>

                    </>)}

                </div>
            </div>
        );

    }
}

export default Leaderboard;