function Machine(props) {

    const colors = [
        ["#71CE62", "#429F32"],
        ["#EAB93D", "#BD901C"],
        ["#E14545", "#A91919"]
    ]

    function getColumnColor(amount) {
        if(amount < 10) {
            return 0;
        } else if(amount < 30) {
            return 1
        } else {
            return 2;
        }
    }

    function showSimulationColumn(amount) {
        return(
            <div className="simulatebottom" style={{height: 64 + amount*2, backgroundColor: colors[getColumnColor(amount)][1]}}>
              <div className="simulatetop" style={{backgroundColor: colors[getColumnColor(amount)][0]}}>
                  {amount}
              </div>
            </div>
        )
    }

    function getWorker(type) {
        if(type == 0) {
            return <img src={require("../img/worker-0.png")} className="worker"/>
        } else if(type == 1) {
            return <img src={require("../img/worker-1.png")} className="worker"/>
        } else if(type == 2) {
            return <img src={require("../img/worker-2.png")} className="worker"/>
        }
    }

    function showFailure() {
        if(props.simulationdata[1] == true) {
            return (<span className="failuremachine"><img src={require("../img/warning_red.png")}/>Machine failing</span>)
        }
    }

    return (
      <div className="machine">
        {props.simulate ? showSimulationColumn(props.simulationdata[0]) : null}
        {props.simulate || props.editor == false ? null : <button onClick={() => props.onDelete()} className="deleteicon"><img src={require("../img/delete.png")}/></button>}
        {props.simulate ? showFailure() : null}
        {getWorker(props.worker)}
        <span onClick={() => props.onCardClick()}>
        <div className="machinetop"><span style={{color: props.bgcolor}}>{props.icon}</span></div>
        <div className="machinebottom" style={{backgroundColor: props.bgcolor}}></div>
        <div className="machinename">{props.name}</div>
        </span>
      </div>
    );
  }

  
  export default Machine;