import { useState } from 'react';
import MachineVisual from "./MachineVisual.js";

function MachineInfo(props) {

    const [worker, setWorker] = useState(props.worker);

    const reparationdata = ["Basic", "✨ Fast", "⚡ Superfast"]


    const styles = {
        smallheadline: {
            margin: 0,
            fontSize: 16,
        },
        maininfo: {
            fontSize: 32,
            margin: 0,
            marginTop: 4,
            marginBottom: 4,
        },
        maininfospan: {
            fontSize: 20,
        },
        smallinfo: {
            margin: 0,
            fontSize: 12,
            color: '#666'
        }
    }

    function getDuration(duration) {
        return Math.floor(60/duration * 10) / 10;
        
    }

    function getDurationText(duration) {
        if(duration == 1) {
            return "1 second";
        } else {
            return duration + " seconds";
        }
    }

    function getDurationColor(duration) {
        if(duration <= 3) {
            return "#71ce62";
        } else if (duration <= 10) {
            return "#ffa500";
        } else {
            return "#f44336";
        }
    }

    function getQuality(scrap) {
        return Math.floor((100 - (100/scrap)) * 10) / 10;
    }

    function getQualityText(scrap) {
        if(scrap == 2) {
            return "2nd"
        } else if (scrap == 3) {
            return "3rd"
        } else {
            return scrap + "th";
        }
    }

    function getGriditemClass(a,b) {
        if(a == b) {
            return "workergriditemactive";
        }
    }

    return (
      <div className="modalwrapper">
          <div className="modalbg" onClick={() => props.onDismiss()}></div>
          <div className="modal" style={{display: 'flex'}}>
            {/*<div className="modalicon">{props.machinedata.icon}</div>*/}
            <div className="modalicon"><MachineVisual icon={props.machinedata.icon} bgcolor={props.bgcolor} /></div>
            <div className="modalcontent">
                <p className="modalheadline">{props.machinedata.name}</p>
                <p className='greentext' style={{fontSize: 16, marginTop: 2 }}>$ {props.machinedata.price}</p>
                <div className="twocolumngrid">
                    <div>
                        <p style={styles.smallheadline}>Speed</p>
                        <p style={styles.maininfo}>{getDuration(props.machinedata.duration)}<span style={styles.maininfospan}>/m</span></p>
                        <p style={styles.smallinfo}>{getDurationText(props.machinedata.duration)} per piece</p>
                    </div>
                    <div>
                        <p style={styles.smallheadline}>Quality</p>
                        <p style={styles.maininfo}>{getQuality(props.machinedata.scrap)}<span style={styles.maininfospan}>%</span></p>
                        <p style={styles.smallinfo}>Scrap every {getQualityText(props.machinedata.scrap)} piece</p>
                    </div>
                </div>


                {props.hideworkers ? null :
                <div className='workergridcont'>
                    {props.worker >= 0 && <div className='reparationtimebadge'>{reparationdata[props.worker]} reparation time</div>}
                    <div className="workergrid">
                        <div onClick={() => props.onWorkerChange(-1)} className={"workergriditem " + getGriditemClass(props.worker, undefined)}><img src={require("../img/worker-none.png")}/>None<span className="greentext">$ 0</span></div>
                        <div onClick={() => props.onWorkerChange(0)} className={"workergriditem " + getGriditemClass(props.worker, 0)}><img src={require("../img/worker2-0.png")}/>Trainee<span className="greentext">$ 5</span></div>
                        <div onClick={() => props.onWorkerChange(1)} className={"workergriditem " + getGriditemClass(props.worker, 1)}><img src={require("../img/worker2-1.png")}/>Technician<span className="greentext">$ 15</span></div>
                        <div onClick={() => props.onWorkerChange(2)} className={"workergriditem " + getGriditemClass(props.worker, 2)}><img src={require("../img/worker2-2.png")}/>Expert<span className="greentext">$ 40</span></div>
                    </div>
                    <p style={{fontSize: 12, marginTop: 24}}><b>Info:</b> Skilled Workers speed up the repair time in case the machine fails and reduces the probability of another failure</p>
                </div>
                }
                <div onClick={() => props.onDismiss()} className='blackbutton'>{props.editor ? "Save & " : null}Close</div>
            </div>
          </div>
      </div>
    );
  }
  
  export default MachineInfo;