import MachineVisual from "./MachineVisual.js";

function Inventory(props) {

    function getDuration(duration) {
        return Math.floor(60/duration * 10) / 10;
        
    }

    function getQuality(scrap) {
        return Math.floor((100 - (100/scrap)) * 10) / 10;
    }

    function InventoryItem(props) {
        return (
            <div className="inventoryitem" style={{opacity: props.machine.hidden ? 0.5 : 1, WebkitFilter: props.machine.hidden ? 'grayscale(100%)' : null}}>
                <div className="inventoryitemleft">
                    <MachineVisual icon={props.machine.icon} bgcolor={props.bgcolor} />
                    <div className="inventoryitemlefttext">
                        <p className="inventoryitemtitle">{props.machine.name}</p>
                        <p className="inventoryiteminfo">{getDuration(props.machine.duration)}/m · Quality {getQuality(props.machine.scrap)}%</p>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {!props.machine.hidden ? <span className="greentext">$ {props.machine.price}</span> : <span className="notavailablebadge">Not available yet</span>}
                    {!props.machine.hidden ? <div onClick={() => props.onAdd(props.step,props.index)} className="inventorybutton">Add</div> : null}
                </div>
            </div>
        )
    }

    return (
      <div className="modalwrapper">
          <div className="modalbg" onClick={() => props.onDismiss()}></div>
          <div className="modal modalinventory">
          <div className='modalcancel' style={{top: 20, right: 20, boxShadow: "none"}} onClick={() => props.onDismiss()}>
              <img src={require("../img/cancel-black.png")} />
            </div>
            <div className="modalcontent" style={{marginTop: 16}}>
                <p style={{textAlign: 'left', fontSize: 28, margin: 0, marginBottom: 32}}>Inventory</p>
                <div>
                    {/* Quality Gate*/}
                    {!props.hidequalitygate &&
                    <div className="inventoryitem">
                        <div className="inventoryitemleft">
                            <img src={require("../img/qualitygate-icon2.png")} style={{width: 88}} />
                            <div className="inventoryitemlefttext">
                                <p className="inventoryitemtitle">Quality Gate</p>
                                <p className="inventoryiteminfo">Remove bad products before shipping</p>
                                <p className="inventoryitembadge">Scrap → 0</p>
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className="greentext">$ 120</span>
                            {!props.reworkgate && <div onClick={() => props.onQualityGateClick()} className="inventorybutton">{props.qualitygate ? "Remove" : "Add"}</div>}
                        </div>
                    </div>
                    }

                    {!props.hidereworkgate &&
                    <div className="inventoryitem" style={{marginTop: 24}}>
                        <div className="inventoryitemleft">
                            <img src={require("../img/reworkgate-icon.png")} style={{width: 88}} />
                            <div className="inventoryitemlefttext">
                                <p className="inventoryitemtitle">Rework Gate</p>
                                <p className="inventoryiteminfo">Rework bad products to make them good</p>
                                <div>
                                    <p className="inventoryitembadge">Output → 100%</p>
                                    <p className="inventoryitembadge">Time: 12/m</p>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className="greentext">$ 120</span>
                            {!props.qualitygate && <div onClick={() => props.onReworkGateClick()} className="inventorybutton">{props.reworkgate ? "Remove" : "Add"}</div>}
                        </div>
                    </div>
                    }
                    
                    {/*<hr></hr>*/}

                    {props.machines.map((obj,step) => {
                        return (
                            <div key={"step-"+step}>
                            <p style={{textAlign: 'left', marginTop: 40, marginBottom: 12, paddingTop: 24, borderTopColor: '#ddd', borderTopWidth: 1, borderTopStyle: 'solid'}}><b>Step {step + 1}</b>{obj.stepname && ": " + obj.stepname}</p>
                            {obj.machines.map((machine, index) => (<InventoryItem key={"machine-"+index} hidden={machine?.hidden} editor={props.editor} machine={machine} bgcolor={obj.color} step={step} index={index} onAdd={(a,b) => props.onAdd(a,b)} />))}
                            </div>
                        )
                    })}
                </div>
            </div>
          </div>
      </div>
    );
  }
  
  export default Inventory;