import { useState, useEffect} from 'react';

function StepsnotrightModal(props) {

    return (
      <div className="modalwrapper">
          <div className="modalbg" onClick={() => props.onDismiss()}></div>
          <div className="modal" style={{maxHeight: '90vh', overflowY: 'scroll'}}>
            <div className="modalcontent" style={{marginTop: 0}}>
                <img src={require("../img/checksteps.png")} style={{width: '100%', maxWidth: 360}} />
                <h2>Check your machines</h2>
                <p>Make sure your steps are in the right order, and that you have at least one machine for each step.</p>
                <div onClick={() => props.onDismiss()} className='blackbutton'>Ok, got it!</div>
            </div>
          </div>
      </div>
    );
  }
  
  export default StepsnotrightModal;