function StartItem(props) {

    function showSimulationColumn(amount) {
      return(
          <div className="simulatebottom-round" style={{height: 64 + amount*2}}>
            <div className="simulatetop-round">
                {amount}
            </div>
          </div>
      )
    }

    return (
        <>
        <div className='item_startend'>
          {props.simulate ? showSimulationColumn(props.amount) : null}
          <div className='item_startend_front colorstart_front'><img src={require("../img/icon_start.png")} /></div>
          <div className='item_startend_bottom colorstart_bottom'></div>
          <div className="machinename" style={{left: -40}}>{props.name}</div>
        </div>
        <img src={require("../img/arrow1-start.png")} className='arrow1' />
        </>
    );
  }

  
  export default StartItem;