import Game from './Game';
import GamePlayground from './GamePlayground';
import GamePlaygroundnew2024 from './GamePlaygroundnew2024.js';
import CreateGame from './CreateGame.js';
import CreateRound from './CreateRound.js';
import GameOverview from './GameOverview.js';
import StartScreen from './StartScreen';
import Leaderboard from './Leaderboardnew.js';
import Login from './Login.js';
import Register from './Register.js';
import LandingPage from './LandingPage';
import StartPage from './StartPage';
import Sandbox from './Sandbox';
import ProfDashboard from './ProfDashboard.js';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Navigate
  } from "react-router-dom";

import ReactGA from "react-ga4";

ReactGA.initialize("G-CJ4KG3FV9G");


function App() {
/*
    return (
        <Router>
            <Routes>
                <Route path='*' element={<Navigate to="/sandbox"/>} />
                <Route path='/sandbox' element={<Sandbox/>} />
            </Routes>
        </Router>
        )
*/
    if(localStorage.getItem('uuid') == null) {
        return (
        <Router>
            <Routes>
                <Route path='/start' element={<StartPage/>} />
                <Route path='*' element={<Navigate to="/start"/>} />
                <Route path='/sandbox' element={<Sandbox/>} />
                <Route path='/professor' element={<Login/>} />
                <Route path='/dashboard' element={<ProfDashboard/>} />
            </Routes>
        </Router>
        )
    } else {
        return (
            <Router>
                <Routes>
                <Route path='/gamenew' element={<GamePlaygroundnew2024/>} />
                <Route path='/gameoverview' element={<GameOverview/>} />
                <Route path='/leaderboard' element={<Leaderboard/>} />
                
                <Route path='*' element={<Navigate to="/gameoverview"/>} />
                <Route path='/playground' element={<GamePlayground/>} />
                <Route path='/sandbox' element={<Sandbox/>} />
                
                </Routes>
            </Router>
        );
    }
  }

  
  export default App;