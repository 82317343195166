import './startscreen.css';
import { useState, useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom";

import HowitworksModal from './components/HowitworksModal.js';


function StartPage() {

    const [input, setInput] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [modalopen, setModalopen] = useState(false);


    const navigate = useNavigate();

    function onJoinClick() {
        if(input == "" || name == "" || email == "") {
            alert("Fill in all fields. You get a game code from your professor.")
        } else {
            sendData();
            //navigate('/gameoverview/');
        }
    }

    function sendData() {
        //Obj of data to send in future like a dummyDb
        const data = {code: input, email: email, name: name };

        //POST request with body equal on data in JSON format
        fetch('https://opex-api.simonrahm.pro/v2/newcreateuser.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
        if(data.uuid) {
            localStorage.setItem('uuid', data.uuid);
            window.location.reload(false);
        } else {
            alert("Something went wrong. Please try again later.");
        }
        })
        //Then with the error generated...
        .catch((error) => {
        console.error('Error:', error);
        alert("Something went wrong. Please try again later.");
        });
    }


    return (
        <div className="landingpage">
        <div className='gradientbg'></div>

        <div className='linkwithimage' style={{position: 'absolute', top: 32, left: 32}} onClick={() => setModalopen(true)}>How it works <img src={require('./img/icon_help.png')} alt='Help Icon' /></div>
        <div className='linkwithimage' style={{position: 'absolute', top: 32, right: 32}} onClick={() => navigate('/professor')}>For teachers <img src={require('./img/icon_logout.png')} alt='Logout Icon' /></div>

        {modalopen && <HowitworksModal onDismiss={() => setModalopen(false)} />}

        
            <div className="centerwrapper">
                {/*<h2 style={{ textAlign: 'center', margin: 0, fontWeight: 'lighter', opacity: 0.6, fontSize: 20}}>Hey {getName()},</h2>*/}
                <img src={require("./img/logoicon.png")} style={{width: '100%', maxWidth: 300, margin: '0px auto', display: 'block'}}/>
                <h1 style={{textAlign: 'center', marginBottom: 80, fontSize: 80, lineHeight: 1, marginTop: 24}}>OPEX Game</h1>

                <input className='codeinput' type='text' placeholder='Enter game code' value={input} onChange={(e) => setInput(e.target.value)} />

                {
                    input != "" && <>
                    <input className='codeinput' type='text' placeholder='Your name' value={name} onChange={(e) => setName(e.target.value)} />
                    <input className='codeinput' type='text' placeholder='Your school email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </>
                }

                <div className='button-l' style={{margin: 0}} onClick={() => onJoinClick()}>Join Game</div>
            </div>
            <footer><a href='https://www.opexagileaudit.eu/' target='_blank'>opexagileaudit.eu</a><a href='https://www.opexagileaudit.eu/impressum.html' target='_blank'>Imprint</a></footer>
        </div>
    );
  }
  
  export default StartPage;