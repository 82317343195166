function Price(props) {
    return (
      <div className="costcont" key={props.price}>
        <span style={{fontSize: 14, marginBottom: 8}}>Spent: <span style={{fontSize: 14, color: '#71CE62'}}>$ {props.price}</span></span>
        <span style={{fontSize: 14}}>Budget left:</span>
        <span style={{fontSize: 32, color: props.budget - props.price >= 0 ? '#71CE62' : '#ff6868'}}>$ {props.budget - props.price}</span>
      </div>
    );
  }
  
  export default Price;