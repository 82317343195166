import { useState, useEffect } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another

function SandboxEdit(props) {

    const [code, setCode] = useState(props.text);

    return (
        <div className="modalwrapper">
            <div className="modalbg" onClick={() => props.onDismiss()}></div>
            <div className="modal" style={{maxHeight: '90vh', overflowY: 'scroll'}}>
                <div className="modalcontent" style={{ marginTop: 0 }}>
                    <h2>Edit the setup</h2>
                    <Editor
                        value={code}
                        onValueChange={code => setCode(code)}
                        highlight={code => highlight(code, languages.js)}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 14,
                        }}
                    />
                    <div onClick={() => props.setSetup(code)} className='blackbutton'>Apply</div>
                </div>
            </div>
        </div>
    );
}

export default SandboxEdit;