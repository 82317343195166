import { useState, useEffect} from 'react';

function LearningsModal(props) {

  useEffect(() => {
    if(props.learnings == undefined) {
      props.goToLeaderboard();
    }
  }, [])


    return (
      <div className="modalwrapper">
          <div className="modalbg" onClick={() => props.onDismiss()}></div>
          <div className="modal" style={{maxHeight: '90vh', overflowY: 'scroll'}}>
            <div className="modalcontent" style={{marginTop: 0}}>
                <h2 style={{fontSize: 32, marginTop: 12}}>{/*💡*/}Take-aways</h2>
                <div className='learningslist'>
                {props.learnings?.map((obj, index) => 
                  <div className='learningitem' key={index}>
                    <span className='learningitem-emoji'>{obj.emoji}</span>
                    <div className='learningitem-text'>
                      <p style={{fontSize: 20, marginBottom: 4}}><b>{obj.title}</b></p>
                      <p style={{fontSize: 16, opacity: 0.7}}>{obj.text}</p>
                    </div>
                  </div>)
                }
                </div>
                
                <div onClick={() => props.goToLeaderboard()} className='blackbutton'>🏆 Go to Leaderboard</div>
            </div>
          </div>
      </div>
    );
  }
  
  export default LearningsModal;