import { useState, useEffect} from 'react';

function HowitworksModal(props) {

  const [page, setPage] = useState(0);

  const data = [
    {
      title: "How to start",
      subtext: "To join a game, enter the game code that your professor gave you. A collection of rounds await you, and in each round you learn a new thing about process optimization.",
      image: require("../img/onboarding-1.png"),
    },
    {
      title: "Build your machines",
      subtext: "Click the 'Build' button to open the inventory to see all your available machines. Notice the different speed, quality and cost, and click 'Add' to place the machine on your field.",
      image: require("../img/onboarding-2.png"),
    },
    {
      title: "Add workers",
      subtext: "Click on a machine to see the details and to add a worker. There are three different skill levels of workers. The better the level, the faster your machine will be repaired in case it fails.",
      image: require("../img/onboarding-3.png"),
    },
    {
      title: "Let’s simulate!",
      subtext: "When you are happy with your process, click 'Finish'. You then switch to the simulation mode where you can watch how your machines perform. Click 'Show Results' to get a breakdown of your costs, time, output, and price per unit.",
      image: require("../img/onboarding-4.png"),
    },
    {
      title: "And the winner is…",
      subtext: "Once you finished you can see the leaderboard. The lowest price per unit wins. But be careful, if you cannot produce the expected output then you won’t be on the leaderboard! Do you think you can make it on #1? Let’s find out!",
      image: require("../img/onboarding-5.png"),
    }
  ]

    return (
      <div className="modalwrapper">
          <div className="modalbg" onClick={() => props.onDismiss()}></div>
          <div className="modal">

            <div className='modalcancel' onClick={() => props.onDismiss()}>
              <img src={require("../img/cancel-black.png")} />
            </div>

            <div className="modalcontent" style={{marginTop: 0}}>
                <img src={data[page].image} style={{width: '100%', borderRadius: 8}} />
                <h2>{data[page].title}</h2>
                <p>{data[page].subtext}</p>
                <div onClick={() => page >= data.length - 1 ? props.onDismiss() : setPage(page + 1)} className='blackbutton'>{page >= data.length - 1 ? "Ok, let's go!" : "Next"}</div>
                {page > 0 ? <div onClick={() => setPage(page - 1)} style={{color: '#555', marginTop: 16, cursor: 'pointer'}}>Previous</div> : null}
            </div>
          </div>
      </div>
    );
  }
  
  export default HowitworksModal;