import './menupages.css';
import { useState, useEffect } from 'react';
import RoundItem from './components/Rounditem.js';
import Loading from './Loading.js';
import {Link, useSearchParams,useNavigate} from "react-router-dom";

import GameInfoModal from './components/GameInfoModal.js';



function GameOverview() {

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({title: 'Game title', rounds: []});

    const [gameinfoopen, setGameInfoOpen] = useState(false);

    const navigate = useNavigate();

    /*
    const mockdata = {
        title: "Bike Repair Inc.",
        description: "Here comes the case...",
        chapters: [
            {
                title: "The Bottleneck",
                image: "https://img.freepik.com/free-vector/vector-illustration-mountain-landscape_1441-72.jpg?w=2000",
                done: false
            },
            {
                title: "Skilled Workers",
                image: "https://blog.tubikstudio.com/wp-content/uploads/2021/02/creative-workspaces-illustrations-tubikarts-5.jpg",
                done: false
            },
            {
                title: "Supplier Crisis",
                image: "https://helpx.adobe.com/content/dam/help/en/illustrator/how-to/drawing-basics/jcr_content/main-pars/image/drawing-basics-intro_900x506.jpg.img.jpg",
                done: false
            },
            {
                title: "Government",
                image: "https://blog.tubikstudio.com/wp-content/uploads/2020/04/Pennine-Alps-illustration-tubikarts-1024x768.png",
                done: false
            },
            {
                title: "Budget Restriction",
                image: "https://maludesign.vn/wp-content/uploads/2022/08/illustration-sandro-rybak-feature.jpg",
                done: false
            }
        ]
    }

    
    const rounds = [
        {name: "Round 4", inteam: false, teamsize: 3},
        {name: "Round 3", inteam: true, finished: false, members: ["Florenz", "Hannah", "Peter"]},
        {name: "Round 2", inteam: true, finished: true, rank: 1, members: ["Simon", "Jakob", "Julia"]},
        {name: "Round 1", inteam: true, finished: true, rank: 3, members: ["Leon", "Aline", "Phillip"]}
    ]
    */

    
    useEffect(() => {
        setLoading(true);
        fetch('https://opex-api.simonrahm.pro/v2/newgetoverview.php', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("uuid"),
        },
        })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
            console.log(data);
            if(data == "fail") {
                alert("Something went wrong. 🤷 Please try again later.")
            } else {
                setData(data);
                setLoading(false);
                if(data?.chapters?.filter(element => element?.done == 1).length == 0) {
                    setGameInfoOpen(true);
                }
            }
        })
        .catch((error) => {
            console.log(error);
            alert("There seems to be a problem with your connection. Please try again later.");
        });

        
 
    }, []);
    

    function mapRounds() {
        return data.rounds.map((obj, index) => <Link key={index} to={"/game/?id=" + obj.url}><RoundItem number={data.rounds.length - index} data={obj} /></Link>)
    }

    function getCardstate(index) {
        if(data.chapters[index]?.done == "1") {
            return 1; // Done/finished
        } else if (data.chapters[index-1]?.done == "1" || index == 0) {
            return 2; // Play now
        } else {
            return 3; // locked
        }
    }

    function logout() {
        const response = window.confirm("Are you sure you want to log out? Your data will be lost.");
        if (response) {
            localStorage.clear();
            window.location.reload(false);
        } 
    }

    if(loading) {
        return (
            <Loading/>
        )
    } else {
        return (
            <div className="gameoverview">
            <div className='gradientbg'></div>

            <div className='gameoverviewwrapper'>
                <div className='gameoverviewmenubar'>
                    <div>
                        <h1>{data.title}</h1>
                        <a className='readcasebtn' onClick={() => setGameInfoOpen(true)}>Read case</a>
                    </div>
                    <div className='medalcontainer' onClick={() => navigate("/leaderboard")}>
                        {/*<Medal rank={1}/>*/}
                        <div><span style={{fontSize: 28}}>{data?.chapters?.filter(element => element?.done == 1).length}</span> / {data?.chapters?.length}</div>
                        <div style={{marginLeft: 20}}>
                            <p style={{fontSize: 18, fontWeight: 'bold'}}>{data?.name}</p>
                            <p style={{fontSize: 14, opacity: 0.6}}>View Leaderboard & Results</p>
                        </div>
                    </div>
                </div>
        

            <div className='chapterwrapper'>
                {
                    data.chapters.map((obj, index) => 
                    <div key={index} className={`chaptercard ${getCardstate(index) == 3 ? "chaptercardlocked" : "chaptercardactive"}`} style={{backgroundImage: `url(${obj.image})`}} onClick={() => getCardstate(index) == 3 ? alert("You first have to finish the rounds before!") : navigate('/gamenew/?chapter=' + obj.id)}>
                        <div className='chaptercardshadow'></div>
                        {getCardstate(index) == 3 ? <img src={require("./img/lock.png")} className='cardlockicon'/> : null}
                        {getCardstate(index) == 2 ? <div className='playnowbtn'>Play now</div> : null}
                        {getCardstate(index) == 1 ? <div className='doneicon'><img src={require("../src/img/checkmark-icon.png")} /></div> : null}
                        <div className='chaptercardtext'>
                            <p>Chapter {index + 1}</p>
                            {getCardstate(index) == 1 || getCardstate(index) == 2 ? <h2>{obj.title}</h2> : null}
                        </div>
                    </div>
                    )
                }
                
            </div>

            </div>

            {gameinfoopen ? <GameInfoModal title={data.title} description={data.description} onDismiss={() => setGameInfoOpen(false)} /> : null}
                
                <div onClick={() => logout()} style={{color: '#fff', textAlign: 'center', padding: 20, cursor: 'pointer'}}>Logout</div>
            </div>
        );
    }
  }
  
  export default GameOverview;