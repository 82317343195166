import StartItem from "./StartItem";
import EndItem from "./EndItem";
import Machine from "./Machine";

function PlaygroundPreview(props) {

  function getArrowBefore(amount) {
    if(amount == 1) {
      return <img src={require("../img/arrow1-end.png")} className='arrow1'/>
    } else if(amount == 2) {
      return <img src={require("../img/arrow2-end.png")} className='arrow2before'/>
    } else if(amount == 3) {
      return <img src={require("../img/arrow3-end.png")} className='arrow3before'/>
    }
  }

  function getArrowAfter(amount) {
    if(amount == 1) {
      return <img src={require("../img/arrow1-start.png")} className='arrow1'/>
    } else if(amount == 2) {
      return <img src={require("../img/arrow2-start.png")} className='arrow2after'/>
    } else if(amount == 3) {
      return <img src={require("../img/arrow3-start.png")} className='arrow3after'/>
    }
  }

  let counter = -1;

    return (
      <div style={{width: '100%', height: 'auto', backgroundColor: '#0003', overflowX: 'scroll', marginTop: 32, borderTopLeftRadius: 16, borderTopRightRadius: 16, padding: '70px 40px', boxSizing: "border-box"}}>
        <div className='playground' style={{height: 'auto', padding: 20 , /*transform: "scale(0.4)"*/ zoom: 0.4}}>
        <StartItem/>
        {
          props.mymachines.map((step,stepindex) => {
            // Loop through every step and display items inside
            return (
              <>
              {getArrowBefore(step.length)}
              <div key={stepindex} className={'gatecontainer gatecontainer' + step.length}>
              {step.map((obj, index) => {
                counter++;
                return <Machine key={stepindex+"."+index} editor={false} icon={props.machines[obj[0]].machines[obj[1]].icon} name={props.machines[obj[0]].machines[obj[1]].name} simulate={false} bgcolor={props.machines[obj[0]].color} worker={obj[2]}/>
              })}
              </div>
              {getArrowAfter(step.length)}
              </>
            )
          })
        }
        
        {/*hasQualityGate*/true ? <div className='qualitygatecontainer'><img src={require("../img/qualitygate.png")} className='qualitygate' /></div> : null }
        <EndItem />
      </div>
      </div>
      );
  }
  
  export default PlaygroundPreview;