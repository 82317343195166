function Medal(props) {

    function getClassname(rank) {
        if(rank == 1) {
            return "medal-gold";
        } else if(rank == 2) {
            return "medal-silver";
        } else if (rank == 3) {
            return "medal-bronze";
        } else if (rank == 0) {
            return "medal-unknown";
        } else {
            return "medal-black";
        }
    }

    return (
      <div className={"medal " + getClassname(props.rank)}>
        {props.rank > 0 ? props.rank : "?"}
      </div>
    );
  }
  
  export default Medal;