/* 
Same as game, but in playground mode:
- No server stuff
- Switch between build and simulate
*/

import './game.css';
import Gametitle from './components/Gametitle.js';
import Medal from './components/Medal.js';
import Teamdots from './components/Teamdots.js';
import Machine from './components/Machine.js';
import StartItem from './components/StartItem.js';
import EndItem from './components/EndItem.js';
import MachineInfo from './components/MachineInfo.js';
import ResultsInfo from './components/ResultsInfo.js';
import Inventory from './components/Inventory.js';
import GameInfoModal from './components/GameInfoModal.js';
import Price from './components/Price.js';
import Finishbutton from './components/Finishbutton.js';
import MachineVisual from "./components/MachineVisual.js";
import SimulationControls from "./components/SimulationControls.js";
import PlaygroundChooseScenarioModal from './components/PlaygroundChooseScenarioModal'; 
import StepsnotrightModal from './components/StepsnotrightModal';
import { useEffect, useState, useRef } from 'react';
import Loading from './Loading.js';
import {useSearchParams,useNavigate} from "react-router-dom";

function GamePlayground() {

  const [title, setTitle] = useState("Game Title");
  const [gamedescription, setGamedescription] = useState("");
  const [members, setMembers] = useState([]);

  const [input, setInput] = useState(80);
  const [output, setOutput] = useState(60);

  const [simulate, setSimulate] = useState(false);
  const [simulationSeconds, setSimulationSeconds] = useState(0);
  const [simulationData, setSimulationData] = useState([]);
  const [simulationData2, setSimulationData2] = useState([]);
  const [speed, setSpeed] = useState(12);
  const [simulationPlaying, setSimulationPlaying] = useState(false);

  const [simulationMachineInfo, setSimulationMachineInfo] = useState([]);

  const [resultsopen, setResultsopen] = useState(false);
  const [results, setResults] = useState({});

  const [editor, setEditor] = useState(true);

  const [inventoryopen, setInventoryopen] = useState(false);
  const [infoopen, setInfoOpen] = useState(false);
  const [gameinfoopen, setGameInfoOpen] = useState(false);
  const [choosescenariomodalopen, setChoosescenariomodalopen] = useState(true);
  const [stepsnotrightmodalopen, setStepsnotrightmodalopen] = useState(false);

  const [hasQualityGate, setHasQualityGate] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [building, setBuilding] = useState(false);

  const [price, setPrice] = useState(0);

  const [mousePosition, setMousePosition] = useState([0,0]);

  const [myMachines, setMyMachines] = useState([]);

  const [machines, setMachines] = useState([]);

  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [bestResult, setBestResult] = useState(null);


  // [[[0,0,1]],[[1,0,2],[1,1]],[[2,0,0]]]

  /*
  {
      color: '#ffbe0b',
      machines: [{icon: "🤖",name: "Machine 1", duration: 1, scrap: 70, price: 600, failure: 50},{icon: "💩",name: "Machine 4", duration: 2, scrap: 20, price: 160}]
    },
    {
      color: '#ff006e',
      machines: [{icon: "⏰",name: "Machine 2", duration: 4, scrap: 5, price: 80, failure: 50},{icon: "🔋",name: "Machine 5", duration: 3, scrap: 30, price: 320},{icon: "🛠️",name: "Machine 6", duration: 1, scrap: 9, price: 210}]
    },
    {
      color: '#3a86ff',
      machines: [{icon: "✂️",name: "Machine 3", duration: 4, scrap: 30, price: 280}]
    }
    */

  function deleteMachine(step, index) {
    // Copy into temporary variable
    let temp = [...myMachines];
    // If length less that 1 -> delete whole array
    if(temp[step].length <= 1) {
      temp.splice(step, 1)
    } else {
      // Else, only delete the object inside
      temp[step].splice(index, 1);
    }
    setMyMachines(temp);
  }

  function startBuilding(step, index) {
    setCurrentStep(step);
    setCurrentIndex(index);
    setMousePosition([0,0]);
    setBuilding(true);
    setInventoryopen(false);
  }

  function addMachine(step, type) {
    let temp = [...myMachines];
    
    if(step == -1) {
      // Add to beginning
      temp.unshift([[currentStep, currentIndex]])
    } else if(type == "new") {
      // Add before or after a machine
      temp.splice(step + 1, 0, [[currentStep, currentIndex]])
    } else if(type == "block") {
      // Add to existing machines (create a gate)
      temp[step].push([currentStep, currentIndex]);
    }
  
    setBuilding(false);
    setMyMachines(temp);
    setInventoryopen(false);
  }

  function openInfoModal(step, index) {
    if(!simulate) {
      setCurrentStep(step);
      setCurrentIndex(index);
      setInfoOpen(true);
    }
  }

  function getWorkerFixTime(worker) {
    let time = 60;
    switch(worker) {
      case 0:
        time = 30;
        break;
      case 1:
        time = 15;
        break;
      case 2:
        time = 5;
        break;
      default:
        time = 60;
    }
    return time;
  }

  function updateBestResult(newresult) {
    if(bestResult === null || newresult < bestResult) {
      setBestResult(newresult);
    }
  }

  function calculateSimulation() {
    const total = input;
    let myinput = total;
    let counters = [];
    let simulationdata = [];

    // For done, scrap, etc‚
    let simulationdata2 = [];
    
    let done = 0;
    let scrap = 0;

    let gateindex = 0;

    for(let sec = 0; sec < 10000; sec++) {
      let count = 0;
      let count2 = 0;

      let gatebuffer = 0;
      let gatebufferafter = 0;

      let simulationsecond = [];

      if(sec > 0) {
        if(myinput > 0) {
          myinput--;
          gatebuffer++;
        }
      }
      // Loop through all steps
      myMachines.forEach((step, stepindex) => {

        gatebufferafter = 0;
        let indexcounter = [];
        let bufferlist = [];

        // Get global indexes of the machines inside this step
        step.forEach(item => {
          indexcounter.push(count2);
          count2++;
        })

        // If there are more than one machine in this step, find out which has a smaller workload
        if(gatebuffer > 0 && indexcounter.length > 1) {
          
          // Save buffers in temporary array
          indexcounter.forEach(indexitem => {
            bufferlist.push(simulationdata[sec-1][indexitem][0]);
          })
          // Set gateindex to the machine with the smaller buffer
          gateindex = bufferlist.indexOf(Math.min(...bufferlist));
        } else {
          // If there is only one machine or no buffer to take, set gateindex to 0
          gateindex = 0;
        }

        // Loop through all machines inside the step
        step.forEach((obj, index) => {
          let machine = machines[obj[0]].machines[obj[1]];

          if(sec == 0) {
            // First second, fill the data
            simulationsecond.push([0, false]);
            counters.push({buffer: 0, isFailing: false, duration: 0, scrap: 0, failure: 0, workerfix: 0});
          } else {
            // Is the machine working?
            if(counters[count].isFailing == false) {
              // Is the machine working on a piece at the moment?
              if(counters[count].buffer > 0) {
                counters[count].duration++;
                counters[count].failure++;
                // Is the machine done with the piece?
                if(counters[count].duration == machine.duration) {
                  counters[count].buffer--;
                  counters[count].duration = 0;
                  counters[count].scrap++;
                  // Is the piece of sufficient quality? If yes: pass on to the next buffer. If no: scrap
                  if(counters[count].scrap == machine.scrap) {
                    scrap++;
                    counters[count].scrap = 0;
                  } else {
                    gatebufferafter++;
                  }
                }
              }
              // Machine fails
              if(counters[count].failure == machine.failure) {
                counters[count].isFailing = true;
                counters[count].workerfix = getWorkerFixTime(obj[2]);
                counters[count].failure = 0;
              }
            } else {
              // Machine is failing. Count down fixing time.
              counters[count].workerfix--;
              // If fixing time is zero, machine will work again
              if(counters[count].workerfix <= 0) {
                counters[count].isFailing = false;
              }
            }
            // Check if this machine should take the load from the buffer
            if(index == gateindex) {
              counters[count].buffer += gatebuffer;
              gatebuffer = 0;
            }
          
            // Push the data to the array
            simulationsecond.push([counters[count].buffer, counters[count].isFailing]);
          }
          count++;
        })
        // Add finished pieces to the buffer of the next step of machines
        gatebuffer += gatebufferafter;
      })
      // End of looping through all steps & machines. Count done pieces and push all data of this second
      done += gatebufferafter;
      simulationdata.push(simulationsecond);

      simulationdata2.push([myinput, done]);

      if((done + scrap) >= total) {
        console.log("You needed " + sec + " seconds!");
        break;
      }
    }

    //console.log(simulationdata);
    // Save data
    setSimulationData(simulationdata);
    setSimulationData2(simulationdata2);
    if(hasQualityGate) {
      scrap = 0;
    }
    setResults({time: simulationdata.length, cost: price, input: total, output: done, scrap: scrap, expectedoutput: output});
    calculateMachineInfo(simulationdata);
    updateBestResult(getCostPerUnit(price, simulationdata.length, scrap, done));
  }


function getCostPerUnit(cost, time, scrap, output) {
    return Math.round((cost + time * 2 + scrap * 20)/output * 100)/100;
}

  function resetSimulation() {
    setSimulationPlaying(false);
    setSimulationSeconds(0);
  }

  function handleTime() {
    setSimulationSeconds(simulationSeconds => simulationSeconds + 1);
  }

  const progressTimer = useRef();

  useEffect(() => {
    if(simulationPlaying) {
        progressTimer.current = setInterval(handleTime, 1000/speed);
        return () => clearInterval(progressTimer.current);
    } 
  }, [simulationPlaying, speed])

  useEffect(() => {
    if (simulationSeconds > simulationData.length) {
      clearInterval(progressTimer.current);
      resetSimulation();
      setResultsopen(true);
    } 
  }, [simulationSeconds]);

  useEffect(() => {
    if (simulate) {
      calculateSimulation();
      resetSimulation();
    } 
  }, [simulate]);

  function calculateMachineInfo(simulationdata) {
    let arr = [];
    simulationdata.forEach(second => {
      second.forEach((obj,index) => {
        if(arr[index] == undefined) {
          arr[index] = [0,0];
        }
        // get new max
        if(obj[0] > arr[index][0]) {
          arr[index][0] = obj[0];
        }
        // increment counter if failure
        if(obj[1] == true) {
          arr[index][1]++;
        }
      })
    });
    setSimulationMachineInfo(arr);
  }

  useEffect(() => {
    getPrice();
  }, [myMachines, hasQualityGate]);

  
  function getPrice() {
    let price = 0;
    // Loop over every machine and get the price
    myMachines.forEach(obj => {
      obj.forEach(x => {
        price += machines[x[0]].machines[x[1]].price;
        // Get price of worker
        if(x[2] == 0) {
          price += 60;
        } else  if(x[2] == 1) {
          price += 90;
        } else  if(x[2] == 2) {
          price += 110;
        }
      })
    })
    if(hasQualityGate) {
      price += 120;
    }
    setPrice(price);
  }

  function getArrowBefore(amount) {
    if(amount == 1) {
      return <img src={require("./img/arrow1-end.png")} className='arrow1'/>
    } else if(amount == 2) {
      return <img src={require("./img/arrow2-end.png")} className='arrow2before'/>
    } else if(amount == 3) {
      return <img src={require("./img/arrow3-end.png")} className='arrow3before'/>
    }
  }

  function getArrowAfter(amount) {
    if(amount == 1) {
      return <img src={require("./img/arrow1-start.png")} className='arrow1'/>
    } else if(amount == 2) {
      return <img src={require("./img/arrow2-start.png")} className='arrow2after'/>
    } else if(amount == 3) {
      return <img src={require("./img/arrow3-start.png")} className='arrow3after'/>
    }
  }

  function changeWorker(worker) {
    let temp = [...myMachines];
    if(worker >= 0 && worker <= 2) {
      // change Worker
      temp[currentStep][currentIndex][2] = worker;
    } else {
      // remove worker
      temp[currentStep][currentIndex].splice(2, 1);
    }
    setMyMachines(temp);
  }

  /*
  function handleMouseMove(e) {
    if(building) {
      //console.log([e.screenX, e.screenY]);
      setMousePosition([e.screenX, e.screenY])
    }
  }
  */

  function getSimulationData(counter) {
    if(simulationData[simulationSeconds] !== undefined) {
      if(simulationData[simulationSeconds][counter] !== undefined) {
        return simulationData[simulationSeconds][counter];
      } else {
        return [0, false];
      }
    } else {
      return [0, false];
    }
  }

  function getSimulationDataStartEnd(type) {
    if(simulationData2[simulationSeconds] !== undefined) {
        return simulationData2[simulationSeconds][type == "start" ? 0 : 1];
      } else {
        return [0, 0];
      }
  }

  function getBuildingButton() {
    if(simulate) {
      return <SimulationControls playing={simulationPlaying} speed={speed} seconds={simulationSeconds} changeSpeed={(x) => setSpeed(x)} changePlaying={(x) => setSimulationPlaying(x)} changeSeconds={(x) => setSimulationSeconds(x)} reset={() => resetSimulation()} maxseconds={simulationData.length-1}/>;
    } else {
      if(building) {
        return <button className='cancelbutton' onClick={() => setBuilding(false)}><img src={require("./img/cancel.png")}/>Cancel</button>
      } else {
        return <button className='plusbutton' onClick={() => setInventoryopen(true)}><img src={require("./img/plus.png")}/>Build</button>
      }
    }
  }

  function getFinishButton() {
    if(simulate) {
      return <div>
          <button className='donebutton' style={{bottom: 100}} onClick={() => setResultsopen(true)}>🔎 Show Results</button>
          <button className='donebutton' onClick={() => backtobuild()}>🔨 Back to Build</button>
        </div>;
    } else {
      return <button className='donebutton' onClick={() => openFinishModal()}>🔮 Simulate</button>;
    }
  }

  function backtobuild() {
    resetSimulation();
    setSimulate(false);
  }

  function checkMachines() {
    let isvalid = true;
    if(myMachines.length != machines.length) {
      isvalid = false;
    }
    myMachines.forEach((step, stepindex) => {
      step.forEach(obj => {
        if(obj[0] != stepindex) {
          isvalid = false;
        }
      })
    })
    return isvalid;
  }

  function openFinishModal() {
    // check if order of the steps is correct
    if(checkMachines()) {
      calculateSimulation();
      setSimulate(true);
    } else {
      setStepsnotrightmodalopen(true);
      //alert("Looks like your steps don't seem right.. Make sure your steps are in the right order, and that you have at least one machine for each step.")
    }
  }

  function finishGame() {
    setSimulate(true);
}

function setMachineFailures(machines, difficulty) {
  // randomly set machine failures based on given difficulty
  if(difficulty == 0) {
    return machines;
  } else {
    // probabilty for failure: 40% on medium, 80% on hard mode
    let probability = difficulty == 1 ? 0.4 : 0.8;
    // minimum time until failure: 30s on medium, 10s on hard mode
    let mintime = difficulty == 1 ? 30 : 10;

    let newmachines = [...machines];
    newmachines.map((step, stepindex) => {
      step.machines.map((machine, index) => {
        if(Math.random() <= probability) {
          machine.failure = Math.floor(Math.random() * (100 - mintime) + mintime);;
        }
      })
    })
    console.log(newmachines);
  }
}

function setScenario(scenario, difficulty) {
  setMachineFailures(scenario.machines, difficulty);
  setTitle(scenario.title);
  setGamedescription(scenario.description);
  setMachines(scenario.machines);
  setChoosescenariomodalopen(false);
  setGameInfoOpen(true);
}

  let counter = -1;


if(loading) {
  return (
      <Loading/>
  )
} else {
  return (
    <div className="app" /*onMouseMove={handleMouseMove}*/>

      {/*building ? <div style={{position: 'absolute', left: mousePosition[0] - 42, top: mousePosition[1] - 200, zIndex: 100}}><MachineVisual icon={machines[currentStep].machines[currentIndex].icon} bgcolor={machines[currentStep].color} building={true} /></div> : null */}

      <div className='gradientbg'></div>
      <div onClick={() => navigate(-1)}><img className='backicon' src={require('./img/icon_back.png')} /></div>
      <Gametitle ontitleclick={() => setGameInfoOpen(true)} title={title} />
      <div className='teamcontainer'>
        <Teamdots members={members} />
        {/*<Medal rank={4}/>*/}
      </div>

      <div className='bottombar'>
        <Price price={price} />
        {getBuildingButton()}
        {getFinishButton()}
        {/*<Finishbutton current={myMachines.length} all={machines.length} />*/}
        {/*<button className='donebutton' onClick={() => setSimulate(!simulate)}>{simulate ? "🔨 Build" : "🔮 Simulate"}</button>*/}
      </div>

      {inventoryopen ? <Inventory editor={editor} onDismiss={() => setInventoryopen(false)} machines={machines} onAdd={(a,b) => startBuilding(a,b)} onQualityGateClick={() => setHasQualityGate(!hasQualityGate)} qualitygate={hasQualityGate} /> : null}

      {infoopen ? <MachineInfo editor={editor} onDismiss={() => setInfoOpen(false)} onWorkerChange={(x) => changeWorker(x)} machinedata={machines[myMachines[currentStep][currentIndex][0]].machines[myMachines[currentStep][currentIndex][1]]} worker={myMachines[currentStep][currentIndex][2]} bgcolor={machines[myMachines[currentStep][currentIndex][0]].color}/> : null}

      {gameinfoopen ? <GameInfoModal title={title} description={gamedescription} onDismiss={() => setGameInfoOpen(false)} /> : null}

      {choosescenariomodalopen ? <PlaygroundChooseScenarioModal setScenario={(x, y) => setScenario(x, y)} /> : null}

      {resultsopen ? <ResultsInfo info={results} onDismiss={() => setResultsopen(false)} goToLeaderboard={() => navigate("/leaderboard/?id=" + searchParams.get('id'))} simulationmachineinfo={simulationMachineInfo} machines={machines} mymachines={myMachines} bestresult={bestResult} hideleaderboard={true}/> : null}

      {stepsnotrightmodalopen ? <StepsnotrightModal onDismiss={() => setStepsnotrightmodalopen(false)} /> : null}

      <div className='playground'>
        <StartItem amount={getSimulationDataStartEnd("start")} simulate={simulate} />
        {building ? <div className='buildplusbutton' onClick={() => addMachine(-1, "new")}><img src={require("./img/plus.png")}/></div> : null }
  {
    myMachines.map((step,stepindex) => {
      // Loop through every step and display items inside
      return (
        <>
        {getArrowBefore(step.length)}
        <div key={stepindex} className={'gatecontainer gatecontainer' + step.length}>
        {step.map((obj, index) => {
          counter++;
          return <Machine key={stepindex+"."+index} editor={editor} icon={machines[obj[0]].machines[obj[1]].icon} name={machines[obj[0]].machines[obj[1]].name} simulate={simulate} simulationdata={getSimulationData(counter)} onDelete={() => deleteMachine(stepindex, index)} bgcolor={machines[obj[0]].color} worker={obj[2]} onCardClick={() => openInfoModal(stepindex, index)}/>
        })}
        {building && step.length < 3 ? <div className='buildplusbutton buildplusbuttoncontainer' onClick={() => addMachine(stepindex, "block")}><img src={require("./img/plus.png")}/></div> : null}
        </div>
        {getArrowAfter(step.length)}
        {building ? <div className='buildplusbutton' onClick={() => addMachine(stepindex, "new")}><img src={require("./img/plus.png")}/></div> : null}
        </>
      )
    })
  }
  
        {hasQualityGate ? <div className='qualitygatecontainer'><img src={require("./img/qualitygate.png")} className='qualitygate' />{simulate || editor == false ? null :<button onClick={() => setHasQualityGate(false)} className="deleteicon"><img src={require("./img/delete.png")}/></button>}</div> : null }
        <EndItem amount={getSimulationDataStartEnd("end")} simulate={simulate} />
      </div>
      
    </div>
  );
}
}

export default GamePlayground;
