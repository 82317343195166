function Teamdots(props) {

    let colors = ["#ffbe0b", "#fb5607", "#ff006e", "#8338ec", "#3a86ff"];

    let initialarray = props.members.slice(0,5);

    let initials = [];

    initialarray.map(obj => {
        let splitname = obj.split(" ");
        let newname = "";
        if(splitname.length > 1) {
          newname = splitname[0].charAt(0) + splitname[1].charAt(0);
        } else {
          newname = splitname[0].charAt(0);
        }
        initials.push(newname);
    })

    function Teamdot(props) {
        return <div className="teamdot" style={{index: props.index, backgroundColor: props.color}}><div className="tooltip">{props.fullname}</div>{props.initials}</div>
    }

    return (
      <div className="teamdotcont">
        {props.members.length > 5 ? <span style={{marginRight: 16, opacity: 0.8}}>+{props.members.length - 5}</span> : <span></span>}
       {
           initials.map((obj, index) => <Teamdot key={index} fullname={initialarray[index]} index={index} color={colors[index]} initials={obj} />)
       }
      </div>
    );
  }
  
  export default Teamdots;