function SimulationControls(props) {

  const speeds = [10, 30, 100, 300];
  
  function changeSpeed() {
    let speedindex = speeds.indexOf(props.speed);
    if(speedindex < speeds.length -1) {
      props.changeSpeed(speeds[speedindex+1])
    } else {
      props.changeSpeed(speeds[0])
    }
  }
  
  function getMinute(time) {
    var minutes = Math.floor(time / 60);
    var seconds = time - minutes * 60;
    return (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

    return (
      <div className="simulationcontrolscont">
        <div style={{position: 'absolute', top: -32, fontSize: 18}}>{getMinute(props.seconds)}</div>
        {/*<input type='range' min={0} max={props.maxseconds}  onChange={(e) => props.changeSeconds(e.target.value)}/>*/}
        <div className='simulationcontrol' onClick={() => props.reset()}><img src={require('../img/media-back.png')} /></div>
        {props.playing ? <div className='simulationcontrol' onClick={() => props.changePlaying(false)}><img src={require('../img/media-pause.png')} /></div> : <div className='simulationcontrol' onClick={() => props.changePlaying(true)}><img src={require('../img/media-play.png')} /></div> }
        <div onClick={() => changeSpeed()} className='simulationcontrol'>{props.speed}x</div>
      </div>
    );
  }
  
  export default SimulationControls;