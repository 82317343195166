import './menupages.css';
import { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";

function Login() {

    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    let navigate = useNavigate();

    function handleLogin() {
        if(email == "") {
            alert("Please enter your email");
        } else if(password == "") {
            alert("Please enter your password");
        } else {
            navigate(`/dashboard?email=${email}&password=${password}`)
        }
    }

    

    return (
        <div className="loginscreen">
        <div className='gradientbg'></div>
        <div onClick={() => navigate("/start")}><img className='backicon' src={require('./img/icon_back.png')} /></div>

        
            <div className="centerwrapper">

                <h1 style={{textAlign: 'center', marginBottom: 56, marginTop: 4}}>Login</h1>
                <input type='text' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                <div onClick={() => handleLogin()} className='button-l' style={{margin: 0}}>Login</div>

                <p className='loginswitchtext'>Do you want to play the OPEX game with your students? <a href='mailto:info@opexagileaudit.eu'>Contact us</a></p>
                
            </div>
        </div>
    );
  }
  
  export default Login;